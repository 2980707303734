import React, { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { makeStyles } from '@material-ui/core'
import { StaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}))

const Industries = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useState(null)
  const [hoverText, setHoverText] = useState(null)

  const multi = [
    { name: 'book-launches', hoverText: 'introduce author and book to <br />reviewing press and media in broad <br />and vertical markets plan and coordinate <br />book signing events that generate maximum <br />sales and coverage' },
    { name: 'branding', hoverText: 'define solid positioning and value <br />proposition for product or corporate message.<br />  cultivate consistent identification, <br />definition and marketing via strategic <br />planning and media coverage.  <br />focus on brand value and integrity <br />to maximize awareness and market share.' },
    { name: 'b2b', hoverText: 'position company brand, key products <br />and core messages that resonate with target <br />industry and trade audiences' },
    { name: 'b2c', hoverText: 'tailor messages to generate editorial <br />coverage and drive consumer awareness' },
    { name: 'collateral', hoverText: 'develop attention-getting newsletters, <br />brochures, fliers, press kits, and websites<br /> with tailored content that target <br />audiences look forward to receiving' },
    { name: 'community', hoverText: 'leverage extensive national and local <br />network to advance client objectives' },
    { name: 'corporate', hoverText: 'build loyalty, pride and good will <br />among company stakeholders including <br />employees and their families, <br />investors, management, vendors and customers' },
    { name: 'crisis-planning', hoverText: 'develop proactive strategies <br />across all communications that safeguard <br />and cultivate client brand reputation' },
    { name: 'event-planning', hoverText: 'plan and coordinate events <br />that embody a client’s key message.  <br />create buzz among target audience <br />and drive media coverage' },
    { name: 'litigation', hoverText: 'collaborate closely with client <br />and counsel to implement a proactive <br />plan that protects client brand equity <br />with the public, key audiences and stakeholders' },
    { name: 'media-relations', hoverText: 'develop, nurture and maintain <br />media relationships build on mutual trust <br />and credibility.  analyze current <br />client communication practices and provide <br />recommendations to increase visibility.  <br />leverage media trends to maximize coverage' },
    { name: 'media-training', hoverText: 'prepare client spokesperson <br />for media contact to ensure clear, concise <br />and consistent key messaging' },
    { name: 'message-strategy', hoverText: 'assess target markets, competition, <br />industry trends and publicity potential.  <br />accordingly, develop strategy and messaging <br />to support and advance client brand' },
  ]

  const classes = useStyles()

  const handlePopoverOpen = (event, popoverId) => {
    console.log({popoverId})
    const values = multi.filter((r) => r.name === popoverId )
    console.log('values: ', values)
    setName(values[0].name)
    setHoverText(values[0].hoverText)  
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => { 
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl);

  return (
    <Layout bgcolor="#fd6721">
      <SEO title="Industries" bgcolor="#fd6721" />
      <h1>Client Industries</h1>
      <p>At <strong>MKL Public Relations</strong>, our dedication to understanding the client’s mission and objectives is an absolute priority. Serving clients in myriad industries, geographies and demographics means our clients never compete with each other for press attention. An expanded industry list renders enables us to identify a wide range of solutions to individual challenges and requests, as well as the ability to develop a presence in otherwise unexplored vertical markets. <strong>MKL PR</strong> services are tailored to meet the individual needs of clients from a diversity of sectors, providing a distinct and well-considered strategy for any event, presentation or campaign.</p>
      <h2>Representative Client Industries</h2>
      <ul>
        <li>Advertising &amp; marketing</li>
        <li>Arts &amp; entertainment</li>
        <li>Books &amp; authors</li>
        <li>Business</li>
        <li>Communications</li>
        <li>Constructions</li>
        <li>Education</li>
        <li>Environment</li>
        <li>Financial services</li>
        <li>Government</li>
        <li>High technology</li>
        <li>Legal</li>
        <li>Lodging &amp; hospitality</li>
        <li>Manufacturing</li>
        <li>Medical</li>
        <li>Medical technology</li>
        <li>Non-profit</li>
        <li>Real estate &amp; development</li>
        <li>Secondary education</li>
        <li>Software</li>
      </ul>

      <Popover
        id={name} open={open} anchorEl={anchorEl} onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'left'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Typography className={classes.typography} dangerouslySetInnerHTML={{ __html: hoverText }} />
      </Popover>

    </Layout>
  )
}

export default Industries
